import React from "react"
import "../../components/animations/scroll.scss"
import TheaterVideo from "@components/Theater/TheaterVideo"

function StaticHeading(props) {
  let dyStart = -2.75
  let dyStartMobile = -3.5
  // if (props.post.svgHeading.length === 4) {
  //   dyStart = -3.5;
  // }
  return (
    <div
      style={{
        textAlign: "left",
      }}
      className="static-heading"
    >
      {/* <h1 id="SEOTitle">{props.post.seoHeading}</h1> */}
      {/* <div className="mobile-headline">
        <h2 className="h1-style">{props.post.mobileHeading}</h2>
      </div> */}
      <div
        className="above-text-container"
        style={{ opacity: "1", transition: "unset" }}
      >
        {/* {props.post.svgHeading.map((heading, i) => (
              <h2
              className="curtain-heading h3-style"
                key={heading}
              >
                {heading}
              </h2>
            ))} */}

        <div
          className="curtain-heading h3-style"
          dangerouslySetInnerHTML={{ __html: props.post.svgHeading.join(" ") }}
        />
      </div>
      <TheaterVideo
        videoUrl={`https://www.youtube.com/watch?v=${props.post.sizzleYoutube}`}
        controls={true}
        playing={true}
        onlyButton={true}
        sizzleButton={true}
        buttonClass="sizzle-button-under contained"
        compId={"sizzleTheaterButton"}
        language={props.language}
      />
    </div>
  )
}

export default StaticHeading
